import React, { Component } from "react";
import axios from "axios";

import Checkout from "./checkoutForm";
import { actions } from "../../API/";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Modal from "../../components/modalResult/";
import Preloader from "../../components/preloader";
import { removeProduct } from "../../redux/menu";
import moment from "moment";

class CheckoutContainer extends Component {
	constructor() {
		super();
		this.state = {
			typeDelivery: [
				{
					name: "delivery",
					id: 1,
				},
				{
					name: "pickup",
					id: 2,
				},
			],
			typePayDelivery: [
				{
					name: "cash",
					id: "09322f46-578a-d210-add7-eec222a08871",
				},
				{
					name: "card",
					id: "54e4c0bd-f9ce-4b75-a263-2a9eb755b9d1",
				},
				{
					name: "online",
					id: "64c3b2ba-ff46-447b-b500-4d03c274c24c",
				},
			],
			typePay: [
				{
					name: "cash",
					id: "09322f46-578a-d210-add7-eec222a08871",
				},
				{
					name: "online",
					id: "64c3b2ba-ff46-447b-b500-4d03c274c24c",
				},
			],
			status: null,
			showModal: false,
			showModalMessage: "",
			preloader: false,
			productsArray: [],
			price: null,
			productsMS: null,
		};

		this.btRef = React.createRef();
		this.formRef = React.createRef();
	}

	componentDidMount() {
		// this.getActualTime();
		window.scrollTo(0, 0);

		let productsList = "";
		let count = "";
		let price = "";
		let amount = 0;

		let productsArray = [];

		let newProduct = this.props.products.filter((item) => item.count > 0);
		let productsMS = "";

		newProduct.map((item) => {
			let modifiersProduct = [];
			let search = productsArray.find((i) => i.id === item.id);

			let sum = 0;
			let price = item.spots[0].price;
			let count = item.count;
			let modifierSum = 0;

			let modifireList = "";

			const { cities, activeCity } = this.props;
			const city = cities.find((t) => t.id === activeCity.id);

			sum += +price;

			if (!search) {
				productsList += `${item["name"][this.props.languageMenu]};`;
				count += `${item["count"]};`;
				price += `${sum};`;
				amount += Number(sum) * Number(item["count"]);

				productsArray.push({
					amount: item["count"],
					id: item["id"],
					name: `${item["name"][this.props.languageMenu]}`,
					modifiers: modifiersProduct,
				});
				productsMS += `${item["name"][this.props.languageMenu]} - ${
					item["count"]
				}; `;
			}
		});

		if (productsArray.length < 1) {
			this.props.history.push("/");
		}

		console.log("productsArray --->>>", productsArray);
		// console.log('productsList --->>>', productsList);

		this.setState({
			productsList,
			count,
			price,
			amount,
			productsArray,
			productsMS,
		});
	}

	// getActualTime = async () => {
	//     await axios.get('http://worldtimeapi.org/api/timezone/Europe/Kiev')
	//         .then(res => {
	//             if (!res) {
	//                 throw new Error('Not get actual time with timezone');
	//             }

	//             if (res && res.status === 200) {
	//                 // console.log('time', res.data.datetime);
	//                 this.setState({ actualTime: res.data.datetime });
	//             }
	//         })
	//         .catch(err => console.log('err', err));
	// }

	onSubmit = (formData) => {
		let self = this;
		let time = formData.time;

		const city = this.props.firebaseCities.find(
			(it) => it.spot_id === this.props.activeCity.id
		);
		const { orderTimeIntervalDelivery, orderTimeIntervalPickup } =
			city.workingHours[0];

		if (!time || time === undefined || formData.selectTime == 1) {
			if (formData.delivery == "1") {
				time = moment()
					.add(orderTimeIntervalDelivery, "minutes")
					.format("HH:mm");
			} else {
				time = moment().add(orderTimeIntervalPickup, "minutes").format("HH:mm");
			}
		}

		this.setState({
			preloader: true,
		});

		let terminals = this.props.cities.find(
			(it) => it.id === this.props.activeCity.id
		).terminals;

		this.priceSend = this.state.price;
		this.amountSend = this.state.amount;

		let productsMS = "";
		let products = [];

		this.state.productsArray.map((item) => {
			console.log("#item", item);
			const { code, name, price, order } = this.props.products.find(
				(product) => product.id === item.id
			);

			products.push({
				id: code,
				name,
				price,
				quantity: order,
			});

			productsMS += `${item["name"]} - ${item["amount"]}; `;
		});

		let price = "";
		let amount = 0;
		let productsArray = [];

		this.props.products.filter((item) =>
			item.count > 0
				? ((price += `${Number(item["price"])};`),
				  (amount += Number(item["price"]) * Number(item["count"])),
				  productsArray.push({
						name: item["name"][this.props.languageMenu],
						count: item["count"],
						price: Number(item.spots[0].price),
				  }))
				: ""
		);

		this.priceSend = price;
		this.amountSend = amount;

		this.setState({
			price,
			amount,
			productsArray,
		});

		let data = {};

		console.log("formData *** --->>>", formData);

		if (formData.delivery !== "2") {
			data = {
				customer: {
					name: formData.name,
					phone: formData.phone,
				},
				order: {
					comment: `${formData.comment}`,
					date: `${moment().format("YYYY-MM-DD")} ${time}:00`,
					isSelfService: false,
					items: this.state.productsArray,
					address: {
						apartment: formData.appartment,
						city: this.props.activeCity.name,
						entrance: formData.porch,
						floor: formData.floor,
						home: formData.house,
						housing: formData.housing,
						street: formData.streetname,
					},
					paymentItems: [
						{
							paymentType: {
								id: "09322f46-578a-d210-add7-eec222a08871",
							},
							// "sum": this.state.amount < 300 ? (this.state.amount / 100) + 30 : (this.state.amount / 100)
							sum: this.state.amount / 100,
						},
					],
					personsCount: "2",
					phone: formData.phone,
				},
				productsMS,
				typePay:
					formData.typePay === "09322f46-578a-d210-add7-eec222a08871"
						? "Наличные"
						: "Картой курьеру",
				city: this.props.activeCity.name,
			};
		} else {
			data = {
				customer: {
					name: formData.name,
					phone: formData.phone,
				},
				deliveryTerminalId: formData.terminals,
				order: {
					comment: formData.comment,
					date: `${moment().format("YYYY-MM-DD")} ${time}:00`,
					isSelfService: true,
					items: this.state.productsArray,
					paymentItems: [
						{
							paymentType: {
								id: "09322f46-578a-d210-add7-eec222a08871",
							},
							// "sum": this.state.amount < 300 ? (this.state.amount / 100) + 30 : (this.state.amount / 100),
							sum: this.state.amount / 100,
						},
					],
					personsCount: "2",
					phone: formData.phone,
				},
				productsMS,
				terminal: terminals.find((it) => it.id === formData.terminals).name,
				typePay:
					formData.typePay === "09322f46-578a-d210-add7-eec222a08871"
						? "Наличные"
						: "Картой курьеру",
				city: this.props.activeCity.name,
			};
		}

		let bt = this.btRef;

		console.log("formData", formData.typePay);

		if (formData.typePay === "64c3b2ba-ff46-447b-b500-4d03c274c24c") {
			// Online Pay
			const number = Math.round(new Date().getTime() / 1000);

			let productList = "";
			let countList = "";
			let totalSumm = 0;
			const products = this.props.products.filter((item) => item.count > 0);

			for (let key in products) {
				const product = products[key];
				productList += product.name + " - " + product.count + ";";
				countList += product.count + ";";
				totalSumm += Number(product.price) * product.count;
			}

			// totalSumm = formData.delivery == '2' || formData.delivery == '1' && totalSumm >= 500 ? totalSumm : totalSumm + 50;

			// console.log('totalSumm *** --->>>', totalSumm);

			data["order"]["paymentItems"][0]["isProcessedExternally"] = false;
			//  data['order']['typePay'] = '09322f46-578a-d210-add7-eec222a08871';

			let dataPay = {
				count: countList,
				id: `N${number}`,
				orderReference: `N${number}`,
				// idProducts: this.state.productsId,
				price: this.priceSend,
				productList,
				products: this.state.productsList,
				// summ: 1,
				summ: this.amountSend / 100,
				time: number,
				totalSumm,
				pickupPointsName: "Нижній Вал 23",
				city: this.props.activeCity.name,
				data: data,
				formData: {
					...formData,
					phone: formData.phone,
					time: moment(time, "kk:mm").format("DD.MM.YYYY kk:mm"),
					comment: `${formData.comment ? formData.comment : ""}  ${
						formData.additionalСomment ? formData.additionalСomment : ""
					} ${formData.callback ? ", ОБОВ'ЯЗКОВО ПЕРЕДЗВОНИТИ" : ""}`,
					myProducts: this.props.products.filter((item) => item.count > 0),
				},
			};

			// console.log('dataPay -->>>', dataPay);
			// console.log('dataPay -->>>', JSON.stringify(dataPay));

			actions
				.getHash(dataPay)
				.then((res) => {
					console.log("hash response", res);
					this.formRef.current[5].value = res.hash;
					this.formRef.current[6].value = "N" + number;
					this.formRef.current[7].value = number;
					this.formRef.current[4].value =
						"http://demo.ithinkers.ru/mirafoodsmarket/payOrder.php/" + res.hash;

					setTimeout(() => {
						// console.log('state', this.state);
						this.btRef.current.click();
					}, 1000);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			data["order"]["paymentItems"][0]["isProcessedExternally"] = false;
			console.log("--- Send order ---", data);
			// console.log("--- data json ---", JSON.stringify(data));

			actions
				.sendOrder(data)
				.then((res) => {
					console.log("res --->>>", res);

					if (res) {
						if (!res.city) {
							this.setState({
								status: false,
								showModalMessage: "Ошибка отправки заказа!",
								showModal: true,
								preloader: false,
							});
						} else {
							self.props.removeProduct();
							this.setState({
								status: true,
								showModalMessage: "Ваш заказ принятий!",
								showModal: true,
								preloader: false,
							});
							// self.props.history.push(`/success/${result.customer.phone}/${result.number}`);
						}
					}
				})
				.catch((err) => {
					console.log("err", err);
					this.setState({
						status: false,
						showModal: true,
						preloader: false,
					});
				});
		}
	};

	getOption = () => {
		const { cash, cardTerminalCourier, card } = this.props.firebaseCities[0];
		let typePay = this.state.typePayDelivery;
		let options = [];

		if (cash) {
			options = [...options, ...typePay.filter((t) => t.name == "cash")];
		}

		if (cardTerminalCourier) {
			options = [...options, ...typePay.filter((t) => t.name === "card")];
		}

		if (card) {
			options = [...options, ...typePay.filter((t) => t.name === "online")];
		}

		return options;
	};

	render() {
		let city = this.props.cities.find(
			(it) => it.id === this.props.activeCity.id
		);
		let terminals = city.terminals;
		let streets =
			this.props.streets &&
			this.props.streets.find((it) => it.city.id === this.props.activeCity.id);

		return (
			<>
				{this.state.showModal && (
					<Modal
						status={this.state.status}
						showModalMessage={this.state.showModalMessage}
					/>
				)}
				{this.state.preloader && <Preloader full={true} />}
				<Checkout
					typeDelivery={this.state.typeDelivery}
					typesPay={city.typePay}
					typePayDelivery={city.typePayDelivery}
					onSubmit={this.onSubmit}
					btRef={this.btRef}
					formRef={this.formRef}
					productsArray={this.state.productsArray}
					amount={this.state.amount}
					getOption={this.getOption}
					initialValues={{
						delivery: 1,
						typePay: this.getOption()[0].id,
						terminals: terminals[0].id,
					}}
					streets={streets}
					terminals={terminals}
					city={city.id}
					actualTime={this.state.actualTime ? this.state.actualTime : null}
				/>
			</>
		);
	}
}

const matStateToProps = (state) => {
	// console.log('state **-->>>', state);
	return {
		languageMenu: state.menu.languageMenu,
		products: state.menu.products,
		cities: state.menu.cities,
		activeCity: state.menu.activeCity,
		streets: state.menu.streets,
		firebaseCities:
			state.menu.data.settings && state.menu.data.settings.City
				? state.menu.data.settings.City
				: [],
	};
};

export default connect(matStateToProps, { removeProduct })(
	withRouter(CheckoutContainer)
);
